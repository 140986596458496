<template>
    <front-layout>
        <div class="page__top-line d-flex flex-column justify-center align-center fill-height pa-5 pb-16">
            <v-card min-width="300" flat max-width="500">
                <v-card-title>Password Reset</v-card-title>
                <v-card-text>
                    <v-form @submit="handleResetPassword">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.email"
                                    label="Email"
                                    disabled v-model="email"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.password"
                                    label="Password"
                                    type="password"
                                    v-model="password"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.password_confirmation"
                                    label="Password Confirmation"
                                    type="password"
                                    v-model="password_confirmation"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-btn @click="handleResetPassword">Reset</v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </front-layout>
</template>

<script>
import FrontLayout from "../layouts/FrontLayout";
import Api from "../api/Api";
import {mapActions, mapMutations} from "vuex";

export default {
    name: "ResetPassword",
    components: {FrontLayout},
    data: function () {
        return {
            email: '',
            token: '',
            password: '',
            password_confirmation: '',
            errors: {},
        }
    },
    methods: {
        async handleResetPassword(e) {
            e.preventDefault()

            const {email, password, password_confirmation, token} = this;
            const response = await Api.resetPassword({
                email,
                password,
                password_confirmation,
                token
            }).catch(e => this.errors = e.response.data.errors);

            if (response.status !== 200) {
                return
            }

            this.errors = {}
            this.$auth.login({
                data: {email, password},
                staySignedIn: true,
                redirect: null
            }).then(res => {
                this.$router.push(this.$loginRedirectRoute(res.data.data.roles))
            }).catch(error => {
                this.errors = error.response.data.errors;
            })
            this.showSnackBar({color: 'success', timeout: 3000, text: 'Password Reset Confirmed'})
        },
        ...mapActions(['setUser']),
        ...mapMutations(['showSnackBar'])
    },
    mounted() {
        this.email = this.$route.query.email
        this.token = this.$route.query.token
    }
}
</script>

<style scoped lang="scss">

</style>
